import mic from "../assets/mic.jpg";
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../App";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { functions } from "../config/firebaseConfig";

const MembershipScreen = () => {
  const {
    userData,
    defaultTheme,
    deviceType,
    setSuccess,
    setInfo,
    setError,
    setMessage,
    dispatch,
    setUserData,
    setCurrentUser,
  } = useContext(AppContext);
  const membershipRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleScrollToMemberships = () => {
    membershipRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const membershipBenefits = [
    "Exclusive Access to Member-Only Events or Shows",
    "Discounted Tickets",
    "Priority Seating",
    "Special Content",
    "Early Access to Ticket Sales",
    "Monthly Newsletter",
    "Meet-and-Greet Opportunities",
    "Merchandise Discounts",
  ];

  const annualBenefits = [
    ...membershipBenefits,
    "Free or Discounted Drinks and Snacks",
    "Exclusive Annual Event",
    "Additional Merchandise Discounts",
    "Special Recognition",
  ];

  const handleSubscription = async (type) => {
    setLoading(true);
    let priceId =
      type === "monthly"
        ? "price_1PopFXJOpsxdxVe2mLs2t6KO"
        : "price_1PopMqJOpsxdxVe2sTLaE2hy";
    if (userData?.guest) {
      // Guest user, prompt to create an account
      setError(true);
      setMessage("Please login or create an account to subscribe.");
      return;
    }
    const uid = userData.uid;
    //call the callable function, and pass in the priceId
    const checkout = functions.httpsCallable("createStripeSubscription");
    const result = await checkout({
      priceId: priceId,
      userId: userData.uid,
      sellerId: process.env.REACT_APP_SITE_ADMIN_ID,
      sellerType: "promoters",
      url: "https://thelaughzonehouseofcomedy.com/memberships",
    });
    if (result?.data?.url) {
      window.location.replace(result.data.url);
    }
    console.log("RESULT => ", result);

    setInfo(true);
    setMessage("Memberships are not yet available. Please try again later.");
    return;

    // const uid = userData?.uid;
    // const checkout = functions.httpsCallable("stripeSubscriptionCheckout");
    // const result = await checkout({
    //   planId: planId,
    //   uid: userData.uid,
    //   stripeLiveKey: process.env.REACT_APP_STRIPE_LIVE_KEY,
    // });
    // if (result.data.url) {
    //   window.location.replace(result.data.url);
    // }

    // if (error) {
    //   setError(error.message);
    // }
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: { xs: "80px", md: "120px" },
        padding: 1,
        background: "black",
      }}
    >
      {/* Hero Section */}
      <Box
        sx={{
          width: "100%",
          height: "70vh",
          backgroundImage:
            "url(https://static.vecteezy.com/system/resources/thumbnails/035/780/333/small_2x/ai-generated-live-comedy-club-where-the-audience-laughs-at-a-stand-up-show-generative-ai-photo.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          color: "white",
          textAlign: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 0,
            background: "rgba(0,0,0,0.5)",
          }}
        />
        <Typography
          color="primary"
          variant={deviceType === "mobile" ? "h4" : "h2"}
          sx={{
            fontWeight: "bold",
            opacity: 1,
            zIndex: 1,
            textShadow: "1px 1px 5px #000000",
          }}
        >
          Join Our Comedy Club!
        </Typography>
        <Typography
          variant={deviceType === "mobile" ? "h6" : "h5"}
          sx={{ marginTop: 1, zIndex: 1 }}
        >
          Enjoy exclusive benefits and hilarious moments with our memberships
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 2, zIndex: 1 }}
          onClick={handleScrollToMemberships}
        >
          See Membership Options
        </Button>
      </Box>

      {/* Blurb Section */}
      <Box
        sx={{
          width: "100%",
          backgroundColor: "black",
          padding: 2,
          color: "white",
          textAlign: "center",
        }}
      >
        <Typography
          color="secondary"
          variant={deviceType === "mobile" ? "h5" : "h4"}
          sx={{ fontWeight: "bold" }}
        >
          Why Join Us?
        </Typography>
        <Typography
          variant={deviceType === "mobile" ? "body1" : "h6"}
          sx={{ marginTop: 1, padding: { xs: "10px", md: "20px 15%" } }}
        >
          Becoming a member of The Laugh Zone House of Comedy means you are part
          of a vibrant community that celebrates humor and creativity. Enjoy
          exclusive access to member-only events, discounts on tickets, priority
          seating, and special content. With our memberships, you not only
          support local comedians but also gain numerous benefits and
          unforgettable experiences. Join us in promoting laughter and
          creativity in Dayton!
        </Typography>
      </Box>

      <Grid
        container
        justifyContent="center"
        sx={{ padding: 2 }}
        ref={membershipRef}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 2,
            width: "100%",
          }}
        >
          <Typography
            variant={deviceType === "mobile" ? "h5" : "h4"}
            color="secondary"
            style={{
              fontWeight: "bold",
            }}
          >
            Memberships
          </Typography>
        </Box>

        <Grid container item xs={12} md={10} spacing={2}>
          {/* Benefits Section */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ marginBottom: 2, color: "white", fontWeight: "bold" }}
            >
              Membership Benefits
            </Typography>
            <List>
              {membershipBenefits.map((benefit, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon
                      sx={{ color: defaultTheme.palette.tirciary.main }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={benefit} sx={{ color: "white" }} />
                </ListItem>
              ))}
            </List>
          </Grid>

          {/* Membership Options Section */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ color: "white", marginBottom: 2, fontWeight: "bold" }}
            >
              Membership Options
            </Typography>
            <Grid container spacing={2}>
              {/* Monthly Membership */}
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{ fontWeight: "bold" }}
                    >
                      Monthly Membership
                    </Typography>
                    <Typography variant="h6" component="div">
                      $30/month
                    </Typography>
                    <List>
                      {membershipBenefits.map((benefit, index) => (
                        <ListItem key={index} style={{ padding: 0 }}>
                          <ListItemText primary={benefit} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                  <CardActions>
                    <Button
                      disabled={loading}
                      size="large"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleSubscription("monthly");
                      }}
                    >
                      {loading ? (
                        <CircularProgress style={{ width: 24, height: 20 }} />
                      ) : (
                        "Join Now"
                      )}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* Annual Membership */}
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{ fontWeight: "bold" }}
                    >
                      Annual Membership
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      $300/year{" "}
                      <Typography
                        variant="caption"
                        style={{
                          background: "green",
                          padding: 4,
                          borderRadius: 4,
                          color: "white",
                          marginLeft: 5,
                          fontWeight: "bold",
                        }}
                      >
                        Save 18%
                      </Typography>
                    </Typography>
                    <List>
                      {annualBenefits.map((benefit, index) => (
                        <ListItem key={index} style={{ padding: 0 }}>
                          <ListItemText primary={benefit} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                  <CardActions>
                    <Button
                      disabled={loading}
                      size="large"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleSubscription("annual");
                      }}
                    >
                      {loading ? (
                        <CircularProgress style={{ width: 24, height: 20 }} />
                      ) : (
                        "Join Now"
                      )}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MembershipScreen;
