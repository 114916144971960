import tony from "../assets/tsanders.jpg";
import jesse from "../assets/jesse.jpg";
import khonda from "../assets/khonda.jpg";

export const musicGenres = [
  "Alternative",
  "Blues",
  "Classical",
  "Country",
  "Dance",
  "Electronic",
  "Folk",
  "Funk",
  "Hip-Hop",
  "Heavy Metal",
  "Indie",
  "Jazz",
  "Latin",
  "Metal",
  "Pop",
  "Punk",
  "R&B",
  "Regge",
  "Rock",
  "Soul",
  "Soundtrack",
  "Spoken Word",
  "World",
  "Other",
];

export const team = [
  {
    name: "Jesse Sanders",
    role: "C.E.O.",
    image: jesse,
    linkedin: "https://www.linkedin.com/in/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    facebook: "https://www.facebook.com/",
  },
  {
    name: "Tony Sanders",
    role: "C.O.O.",
    image: tony,
    linkedin: "https://www.linkedin.com/in/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    facebook: "https://www.facebook.com/",
  },
  {
    name: "Khonda Sanders",
    role: "General Manager",
    image: khonda,
    linkedin: "https://www.linkedin.com/in/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    facebook: "https://www.facebook.com/",
  },
];

export const getTicketFee = (price) => {
  console.log("THIS IS THE PRICE", price);
  if (price === 0) {
    return 0.0;
  } else if (price > 0 && price < 31) {
    return 2.99;
  } else if (price >= 31 && price < 85) {
    return 4.99;
  } else if (price >= 85) {
    return price * 0.06;
  } else {
    return undefined; // or any other default value you prefer
  }
};
