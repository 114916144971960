import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { AppContext } from "../App";

const UpcomingShows = ({ showsRef }) => {
  const { defaultTheme, events, navigate, scrollToTop, artist } =
    useContext(AppContext);
  const [upcomingEvents, setUpcomingEvents] = useState(null);

  useEffect(() => {
    if (events.length > 0 && !upcomingEvents) {
      let eventsData = events.slice();
      const nonTestEvents = eventsData.filter((event) => !event.test);
      console.log("non test events =>", nonTestEvents);
      const nonHiddenEvents = nonTestEvents.filter((event) => !event.hidden);
      console.log("non hidden events =>", nonHiddenEvents);
      const currentEvents = nonHiddenEvents.filter(
        (e) =>
          new Date(e.startDate.seconds * 1000).getTime() > new Date().getTime()
      );
      console.log("current events =>", currentEvents);

      setUpcomingEvents(currentEvents);
    }
  }, [events, upcomingEvents]);

  const url = (input, id) => {
    // Remove all special characters except spaces
    let cleaned = input.replace(/[^\w\s]/gi, "");
    // Replace spaces with hyphens and convert to lowercase
    cleaned = cleaned.replace(/\s+/g, "-").toLowerCase();

    let url = cleaned + `ev_${id}`;

    return url;
  };

  return (
    <Grid
      item
      container
      xs={12}
      //   sx={{ height: { xs: "100dvh", md: "70dvh" } }}
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "fit-content",
      }}
      ref={showsRef}
    >
      <Grid
        item
        container
        xs={12}
        md={8}
        sx={{
          minHeight: { xs: "70dvh", md: "60dvh" },
          maxHeight: { md: "90dvh" },
          //   fontSize:
        }}
        style={{
          textAlign: "center",
          background: defaultTheme.palette.black,
          padding: 20,
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            alignItems: "center",
            height: "fit-content",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: defaultTheme.palette.white,
              width: "100%",
              marginBottom: "20px",
              fontWeight: "bold",
            }}
          >
            Upcoming Events
          </Typography>
          <Typography
            variant="caption"
            style={{ color: defaultTheme.palette.white }}
          >
            All shows are 18+ to enter and 21+ to purchase alcohol.
            <br />
          </Typography>

          <Grid item container xs={12} md={9}>
            {upcomingEvents?.length > 0 ? (
              upcomingEvents.slice(0, 2).map((event) => {
                const { title, date, location, image, id } = event;
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      alignItems: "center",
                      borderBottom: "1px solid white",
                      padding: "10px",
                    }}
                  >
                    <Grid item container xs={12} md={8} lg={9}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: defaultTheme.palette.white,
                          fontWeight: "bold",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {new Date(
                          event.startDate.seconds * 1000
                        ).toLocaleDateString()}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: defaultTheme.palette.white,
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {event.title}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} md={4} lg={3}>
                      <Button
                        onClick={() => {
                          navigate(`/events/${url(title, id)}`);
                          scrollToTop();
                        }}
                        variant="outlined"
                        color="tirciary"
                        fullWidth
                        style={{ borderRadius: 40, height: 40 }}
                      >
                        Details
                      </Button>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Typography
                variant="h6"
                sx={{
                  color: defaultTheme.palette.white,
                  width: "100%",
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                No upcoming shows
              </Typography>
            )}
            <Grid
              item
              container
              xs={12}
              style={{ padding: 20, justifyContent: "center" }}
            >
              <Button
                color="primary"
                variant="contained"
                style={{ borderRadius: 40, marginTop: 20, width: "50%" }}
                onClick={() => {
                  navigate("/events");
                  scrollToTop();
                }}
              >
                See More
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={4}
        sx={{
          display: { xs: "none", md: "flex" },
          minHeight: { xs: "70dvh", md: "60dvh" },
          maxHeight: { md: "90dvh" },
        }}
        style={{
          textAlign: "center",
          background: defaultTheme.palette.black,
          justifyContent: "center",
          padding: 20,
          alignItems: "center",
        }}
      >
        {events?.length > 0 ? (
          <img
            onClick={() => {
              navigate(`/events/${url(events[0].title, events[0].id)}`);
              scrollToTop();
            }}
            src={events[0].image}
            style={{
              width: "100%",
              height: "100%",
              onbjectFit: "cover",
              cursor: "pointer",
            }}
          />
        ) : (
          <img
            onClick={() => {
              navigate(`/events/${url(events[0].title, events[0].id)}`);
              scrollToTop();
            }}
            src={artist.imageUrl}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default UpcomingShows;
