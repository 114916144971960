// src/screens/HomeScreen.js
import React, { useContext, useEffect } from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import { motion } from "framer-motion";
import Hero from "../components/Hero";
import About from "../components/About";
import UpcomingShows from "../components/UpcomingShows";
import Contact from "../components/Contact";
import { AppContext } from "../App";
import { team } from "../utils/contants";
import { Facebook, Instagram, LinkedIn, X } from "@mui/icons-material";

const HomeScreen = ({ navigate, homeRef, showsRef, contactRef }) => {
  const { setViewMenu } = useContext(AppContext);
  //   const imageGallery = [
  //     img1,
  //     img2,
  //     img3,
  //     img4,
  //     img5,
  //     img11,
  //     img6,
  //     img7,
  //     img8,
  //     img9,
  //     img10,
  //   ];

  useEffect(() => {
    setViewMenu(true);
  }, []);

  return (
    <Grid item container xs={12} style={{ height: "fit-content", zIndex: 1 }}>
      <Hero homeRef={homeRef} />
      <UpcomingShows showsRef={showsRef} />
      <About />
      {/* <Grid item container sx={{ display: { xs: "none", md: "block" } }}>
        <Box sx={{ overflow: "hidden", width: "100%" }}>
          <motion.div
            style={{
              display: "flex",
              width: "fit-content",
              marginBottom: "5px",
            }}
            animate={{ x: ["20%", "-100%"] }}
            transition={{ duration: 120, repeat: Infinity, ease: "linear" }}
          >
            {imageGallery.concat(imageGallery).map((img, index) => (
              <motion.img
                key={index}
                src={img}
                alt={`carousel-img-${index}`}
                style={{
                  width: "260px",
                  height: "260px",
                  margin: "0 2px",
                  objectFit: "cover",
                }}
              />
            ))}
          </motion.div>
          <motion.div
            style={{ display: "flex", width: "fit-content" }}
            animate={{ x: ["-90%", "0%"] }}
            transition={{ duration: 60, repeat: Infinity, ease: "linear" }}
          >
            {imageGallery
              .reverse()
              .concat(imageGallery)
              .map((img, index) => (
                <motion.img
                  key={index}
                  src={img}
                  alt={`carousel-img-${index}`}
                  style={{
                    width: "260px",
                    height: "260px",
                    margin: "0 2px",
                    objectFit: "cover",
                  }}
                />
              ))}
          </motion.div>
        </Box>
      </Grid> */}

      {/* TEAM */}
      <Grid
        item
        container
        xs={12}
        style={{ background: "white", justifyContent: "center" }}
      >
        <Typography
          variant="h4"
          color="primary"
          sx={{
            margin: "20px 0px",
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Our Team
        </Typography>
        {team.map((member, index) => (
          <Grid
            item
            container
            xs={12}
            md={3}
            key={index}
            sx={{ margin: "20px 0px", justifyContent: "center" }}
          >
            <Grid item xs={8} md={6}>
              <img
                src={member.image}
                alt={member.name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "200px",
                }}
              />
            </Grid>
            <Grid item container xs={12} style={{ justifyContent: "center" }}>
              <Typography
                variant="h6"
                sx={{ marginTop: "20px", textAlign: "center", width: "100%" }}
              >
                {member.name}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: "20px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {member.role}
              </Typography>
              <Grid
                item
                container
                xs={8}
                md={6}
                style={{ justifyContent: "space-evenly" }}
              >
                <IconButton>
                  <Facebook />
                </IconButton>
                <IconButton>
                  <LinkedIn />
                </IconButton>
                <IconButton>
                  <X />
                </IconButton>
                <IconButton>
                  <Instagram />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Contact contactRef={contactRef} />
    </Grid>
  );
};

export default HomeScreen;
