import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { AppContext } from "../App";
import tony from "../assets/tony2.png";
import tonyt from "../assets/gallery/tonyt.png";
import tamar from "../assets/gallery/tamar.png";
import toryandglenn from "../assets/gallery/torey-glenn.png";
import troop from "../assets/gallery/troop.png";

const About = () => {
  const { defaultTheme, artist } = useContext(AppContext);
  const [currentImageIdx, setCurrentImageIdx] = useState(0);

  const images = [tonyt, tamar, toryandglenn, troop];

  //a use effect that updates the currentImageIndex every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIdx((currentImageIdx) => currentImageIdx + 1);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentImageIdx === images.length) {
      setCurrentImageIdx(0);
    }
  }, [currentImageIdx]);

  return (
    <Grid
      item
      container
      xs={12}
      //   sx={{ height: { xs: "100dvh", md: "70dvh" } }}
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "fit-content",
        background: "black",
      }}
    >
      <Grid
        item
        container
        xs={12}
        md={5}
        sx={{
          minHeight: { xs: "70dvh", md: "60dvh" },
          maxHeight: { md: "70dvh" },
        }}
        style={{
          textAlign: "center",
          background: "transparent",
          padding: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={images[currentImageIdx]}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          minHeight: { xs: "70dvh", md: "60dvh" },
          maxHeight: { md: "90dvh" },
        }}
        style={{
          textAlign: "center",
          background: defaultTheme.palette.black,
          padding: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          color="secondary"
          sx={{
            fontWeight: "bold",
            marginTop: { xs: "20px", md: "40px" },
          }}
        >
          About Us.
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginTop: { md: "60px" } }}
          style={{ whiteSpace: "pre-line", color: "white" }}
        >
          The Laugh Zone House of Comedy is a unique entertainment destination
          serving the West/Northwest side of Dayton, Montgomery County, OH, USA.
          Our establishment is dedicated to providing a platform for local
          comedians to showcase their talent through stand-up performances and
          improv nights. We strive to create a welcoming and inclusive space for
          laughter and creativity, setting us apart as a hub for entertainment
          and community engagement.
          <br />
          <br />
          The Laugh Zone House of Comedy aims to be the premier destination for
          comedy lovers in the Dayton and surrounding areas, offering a variety
          of entertainment options such as stand-up performances, improv nights,
          and special events. We are committed to promoting local comedians and
          providing a space for them to share their talents with the community.
          <br />
          <br />
        </Typography>
        <Typography
          variant="h4"
          color="secondary"
          sx={{
            fontWeight: "bold",
            marginTop: { xs: "20px", md: "40px" },
          }}
        >
          Our Mission.
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginTop: { md: "60px" } }}
          style={{ whiteSpace: "pre-line", color: "white" }}
        >
          We aim to create a supportive and inclusive environment for local and
          national comedians to showcase their talent, while providing quality
          entertainment for our patrons. We are dedicated to fostering
          creativity and laughter within our community.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default About;
