// src/App.js
import React, { useState, createContext, useRef, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import SplashScreen from "./screens/SpashScreen";
import AuthScreen from "./screens/AuthScreen";
import HomeScreen from "./screens/HomeScreen";
import ShowsScreen from "./screens/ShowsScreen";
import EventScreen from "./screens/EventScreen";
import ProfileScreen from "./screens/ProfileScreen";
import SettingsScreen from "./screens/SettingsScreen";
import MyTicketsScreen from "./screens/MyTicketsScreen";
import DashboardScreen from "./screens/DashboardScreen";
import AdminScreen from "./screens/AdminScreen";
import NotificationScreen from "./screens/NotificationScreen";
import SupportScreen from "./screens/SupportScreen";
import { Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MenuBar from "./components/MenuBar";
import background from "./assets/stage.png";
import { theme as defaultTheme } from "./Theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./components/Footer";
import { setUserData, setCurrentUser } from "./globalState/authActions";
import firebase, { db, auth } from "./config/firebaseConfig";
import BottomNavBar from "./components/BottomNavBar";
import Toast from "./components/Toast";
import CheckInTicket from "./components/CheckInTicket";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsOfService from "./screens/TermsOfService";
import MusicPlayer from "./components/@extended/MusicPlayer";
import RegistrationScreen from "./screens/RegistrationScreen";
import MembershipScreen from "./screens/MembershipScreen";
import { getTicketFee } from "./utils/contants";

auth
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    // Existing and future Auth states will now persist locally across tabs and windows.
    // If a user opens the app in a new tab or window, they will be signed in automatically.
  })
  .catch((error) => {
    // Handle errors here
    console.error("Error setting auth persistence: ", error);
  });

export const AppContext = createContext({});

const testEvents = [
  {
    id: "12345678",
    title: "Event 1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    date: "2024-12-01",
    time: "12:00",
    location: "Event 1 location",
    image:
      "https://i.pinimg.com/originals/9b/87/0b/9b870b29291ee7502d0ec99ab3b6733d.png",
    tickets: [
      {
        id: "tid_vjovq24g92357g",
        name: "Ticket 1",
        price: 45.0,
        quantity: 10,
        sold: 0,
        remaining: 10,
        limit: 5,
      },
      {
        id: "tid_vslniwruhtp329",
        name: "Ticket 2",
        price: 100.0,
        quantity: 10,
        sold: 0,
        remaining: 10,
        limit: 5,
      },
    ],
  },
  {
    id: "23456789",
    title: "Event 2",
    description: "Event 2 description",
    date: "2024-12-02",
    time: "12:00",
    location: "Event 2 location",
    image:
      "https://i.pinimg.com/originals/9b/87/0b/9b870b29291ee7502d0ec99ab3b6733d.png",
    tickets: [
      {
        id: "tid_vjovq24g92357g",
        name: "Ticket 1",
        price: 10,
        quantity: 10,
        sold: 0,
        remaining: 10,
        limit: 5,
      },
      {
        id: "tid_vslniwruhtp329",
        name: "Ticket 2",
        price: 10,
        quantity: 10,
        sold: 0,
        remaining: 10,
        limit: 5,
      },
    ],
  },
];

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userData = useSelector((state) => state.auth.userData);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(true);
  const [info, setInfo] = useState(false);
  const [message, setMessage] = useState("Welcome!");
  const [viewMenu, setViewMenu] = useState(false);
  const [deviceType, setDeviceType] = useState(null);
  const [events, setEvents] = useState(null);
  const [cart, setCart] = useState([]);
  const [artist, setArtist] = useState(null);
  const [artistID, setArtistID] = useState(null);

  const adminUIDs = [
    process.env.REACT_APP_PLATFORM_ADMIN_ID,
    process.env.REACT_APP_SITE_ADMIN_ID,
  ];

  //   console.log("ADMIN UIDS..", adminUIDs);

  const path = window.location.pathname;
  //   console.log("PATH => ", path);

  const homeRef = useRef(null);
  const showsRef = useRef(null);
  const contactRef = useRef(null);
  const pageContainerRef = useRef(null);

  const scrollToTop = (ref) => {
    if (ref) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      if (pageContainerRef.current) {
        pageContainerRef.current.scrollTo(0, 0); // Use the ref to scroll to top
      }
    }
  };

  const values = {
    scrollToTop,
    homeRef,
    showsRef,
    contactRef,
    adminUIDs,
    viewMenu,
    setViewMenu,
    defaultTheme,
    events,
    setEvents,
    userData,
    setCurrentUser,
    currentUser,
    setUserData,
    dispatch,
    useSelector,
    setLoading,
    success,
    setSuccess,
    info,
    error,
    message,
    setError,
    setInfo,
    setMessage,
    navigate,
    cart,
    setCart,
    artist,
    artistID,
    deviceType,
    getTicketFee,
  };

  const fetchEvents = async () => {
    try {
      const platfomrArtist = await db
        .collection("promoters")
        .doc(process.env.REACT_APP_SITE_ADMIN_ID)
        .get()
        .then((doc) => doc.data());

      const artistId = platfomrArtist.uid;

      if (artistId) {
        const eventsQuery = await db
          .collection("events")
          .where("artistId", "==", artistId)
          .get();

        const eventsData = eventsQuery.docs.map((doc) => doc.data());
        setArtist(platfomrArtist);
        setArtistID(artistId);
        console.log(`WE HAVE ${eventsData.length} EVENTS FOR THIS ARTIST`);
        setEvents(eventsData);
      }
    } catch (error) {
      console.error("Error fetching events: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();

    if (!deviceType) {
      setDeviceType(window.innerWidth < 768 ? "mobile" : "desktop");
    }
    console.log("currently viewing as =>", deviceType);
  }, [deviceType]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log("USER ", user);
        try {
          await db
            .collection("admins")
            .doc(user.uid)
            .get()
            .then(async (doc) => {
              if (doc.exists) {
                const userData = doc.data();
                dispatch(setUserData(userData));
                dispatch(setCurrentUser(user));
              } else {
                await db
                  .collection("artists")
                  .doc(user.uid)
                  .get()
                  .then(async (doc) => {
                    if (doc.exists) {
                      const userData = doc.data();
                      dispatch(setUserData(userData));
                      dispatch(setCurrentUser(user));
                    } else {
                      const userDoc = await db
                        .collection("users")
                        .doc(user.uid)
                        .get();
                      if (userDoc.exists) {
                        const userdata = userDoc.data();
                        console.log("USER EXISTS =>", userData, userdata);
                        dispatch(setUserData(userdata));
                        dispatch(setCurrentUser(user));
                      } else {
                        const userdata = {
                          uid: user.uid,
                          name: "GUEST",
                          roles: ["user"],
                          guest: true,
                        };
                        console.log("USER DOES NOT EXIST =>", userdata);
                        dispatch(setUserData(userdata));
                        dispatch(setCurrentUser(user));
                      }
                    }
                  });
              }
            });
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        await auth.signInAnonymously().then(async (userCredential) => {
          const user = userCredential.user;
          dispatch(setCurrentUser(user));
          dispatch(
            setUserData({
              uid: user.uid,
              name: "GUEST",
              roles: ["user"],
              guest: true,
            })
          );
        });
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    console.log("DEBUG USERDATA", userData);
  }, [userData]);

  return (
    <AppContext.Provider value={values}>
      <ThemeProvider theme={createTheme(defaultTheme)}>
        <div
          className="App"
          style={{
            height: "100dvh",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            background: "black",
          }}
        >
          <Toast />
          {/* {!loading && <MusicPlayer />} */}
          {loading ? (
            <SplashScreen />
          ) : (
            <Grid
              ref={pageContainerRef}
              item
              container
              xs={12}
              justifyContent={"center"}
              style={{
                background: "black",
                // background: `linear-gradient(180deg, #ffffff 60%, #00000050 100%)`,
                maxHeight: "100dvh",
                overflowY: "scroll",
              }}
            >
              {path === "/" && (
                <img
                  alt=""
                  src={background}
                  style={{
                    position: "absolute",
                    position: "absolute",
                    top: "0%",
                    left: "50%",
                    transform: "translate(-50%, 0%)",
                    height: "100%",
                    width: "98%",
                    objectFit: "cover",
                    objectPosition: "top center",
                    zIndex: 0,
                    opacity: 0.25,
                  }}
                />
              )}
              <Grid
                item
                container
                xs={11}
                style={{
                  position: "absolute",
                  top: "5%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  background: "#00000025",
                  boxShadow: "2px 2px 6px #00000050",
                  borderRadius: 12,
                  overflow: "hidden",
                  zIndex: 100,
                }}
              >
                <MenuBar navigate={navigate} />
              </Grid>

              <Routes>
                <Route path="/" element={<HomeScreen navigate={navigate} />} />
                <Route
                  path="/auth"
                  element={<AuthScreen navigate={navigate} />}
                />
                <Route
                  path="/events"
                  element={<ShowsScreen navigate={navigate} />}
                />
                <Route
                  path="/events/:id"
                  element={<EventScreen navigate={navigate} />}
                />
                <Route
                  path="/events/:id/success"
                  element={<EventScreen navigate={navigate} />}
                />
                <Route
                  path="/account"
                  element={<ProfileScreen navigate={navigate} />}
                />
                <Route
                  path="/settings"
                  element={<SettingsScreen navigate={navigate} />}
                />
                <Route
                  path="/tickets"
                  element={<MyTicketsScreen navigate={navigate} />}
                />
                <Route
                  path="/dashboard"
                  element={<DashboardScreen navigate={navigate} />}
                />
                <Route
                  path="/admin"
                  element={<AdminScreen navigate={navigate} />}
                />
                <Route
                  path="/notifications"
                  element={<NotificationScreen navigate={navigate} />}
                />
                <Route
                  path="/support"
                  element={<SupportScreen navigate={navigate} />}
                />
                <Route
                  path="/:eventId/:userId/:ticketId"
                  element={<CheckInTicket />}
                />

                <Route
                  path="/register-to-perform"
                  element={<RegistrationScreen />}
                />
                <Route path="/memberships" element={<MembershipScreen />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
              </Routes>

              <Grid
                item
                container
                xs={11}
                sx={{ display: { sx: "flex", md: "none" } }}
                style={{
                  position: "absolute",
                  bottom: "2%",
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  background: "#00000025",
                  boxShadow: "2px 2px 6px #00000050",
                  borderRadius: 12,
                  overflow: "hidden",
                  zIndex: 100,
                }}
              >
                <BottomNavBar navigate={navigate} />
              </Grid>

              <Footer />
            </Grid>
          )}
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
