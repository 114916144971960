import mic from "../assets/mic.jpg";
import {
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Paper,
  InputBase,
  CircularProgress,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import { auth, db, functions } from "../config/firebaseConfig";
import { EmailAuthProvider } from "firebase/auth";
import { getAuth, linkWithCredential } from "firebase/auth";
import { motion } from "framer-motion";
import { Send } from "@mui/icons-material";

const RegistrationScreen = () => {
  const {
    defaultTheme,
    deviceType,
    setSuccess,
    setError,
    setMessage,
    dispatch,
    setUserData,
    setCurrentUser,
  } = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [artistName, setArtistName] = useState("");
  const [genre, setGenre] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState({
    instagram: "",
    twitter: "",
    facebook: "",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (event, type) => {
    switch (type) {
      case "stage name":
        setArtistName(event.target.value);
        break;
      case "genre":
        setGenre(event.target.value);
        break;
      case "name":
        setFullName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "phone":
        setPhoneNumber(event.target.value);
        break;
      case "instagram":
        setSocialMediaLinks((prev) => ({
          ...prev,
          instagram: event.target.value,
        }));
        break;
      case "twitter":
        setSocialMediaLinks((prev) => ({
          ...prev,
          twitter: event.target.value,
        }));
        break;
      case "facebook":
        setSocialMediaLinks((prev) => ({
          ...prev,
          facebook: event.target.value,
        }));
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!fullName) tempErrors.fullName = "Full name is required.";
    if (!email) tempErrors.email = "Email is required.";
    if (!phoneNumber) tempErrors.phoneNumber = "Phone number is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSendRegisitrationEmail = async (onboardingData) => {
    console.log(onboardingData);
    const performerType = genre === "Poetry" ? "Poet" : "Comedian";
    try {
      const contactMe = functions.httpsCallable("contactBookingAgent");
      const response = await contactMe({
        agent: "thelaughzonehouseofcomedy@gmail.com",
        name: fullName,
        email,
        phone: phoneNumber,
        subject: "New Registration",
        message: `${performerType}, ${fullName} has registered to be a performer at The Laugh Zone!`,
      });

      console.log("RESPONSE =>", response);

      if (response.data) {
        setSuccess(true);
        setMessage("Registration sent successfully!");
        handleClearForm();
      } else {
        setError(true);
        setLoading(false);
        setMessage("Error sending message. Please try again.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setError(true);
      setLoading(false);
      setMessage("Error sending message. Please try again.");
    }
  };

  const handleClearForm = () => {
    setFullName("");
    setEmail("");
    setPhoneNumber("");
    setSocialMediaLinks({
      instagram: "",
      twitter: "",
      facebook: "",
    });
    setErrors({});
  };

  const handleSignUp = async () => {
    if (!validateForm()) return;
    setLoading(true);

    const credential = EmailAuthProvider.credential(email, password);

    if (password !== confirmPassword) {
      setError(true);
      setMessage("Passwords do not match");
      return;
    }

    try {
      const auth = getAuth();
      linkWithCredential(auth.currentUser, credential)
        .then(async (usercred) => {
          const user = usercred.user;
          console.log("Anonymous account successfully upgraded", user);

          console.log("User ID => ", user.uid);

          const performerData = {
            artistFullName: fullName,
            adminName: fullName,
            name: artistName,
            phone: phoneNumber,
            email,
            adminEmail: email,
            uid: user.uid,
            roles: ["user", "artist"],
            guest: false,
            test: false,
            imageUrl: "",
            bio: "",
            isArtist: true,
            address: "",
            payoutMethod: "",
            worksWithPromoters: "yes",
            preferredCommunication: "",
            socialMediaLinks,
            genre: genre,
            websiteUrl: "",
            infoSubmitted: false,
            routingNumber: "",
            accountNumber: "",
            taxIDNumber: "",
          };

          let genreCollection = genre === "Poetry" ? "poets" : "comedians";

          await db
            .collection("artists")
            .doc(performerData.uid)
            .set(performerData);
          await db
            .collection("promoters")
            .doc(process.env.REACT_APP_SITE_ADMIN_ID)
            .collection(genreCollection)
            .doc(performerData.uid)
            .set(performerData);

          await handleSendRegisitrationEmail(performerData);

          dispatch(setCurrentUser(user));
          dispatch(setUserData(performerData));
          setSuccess(true);
          setMessage(`Account Created successfully. Welcome, ${fullName}!`);
        })
        .catch((error) => {
          console.log("Error upgrading anonymous account", error);
        });
    } catch (error) {
      setError(true);
      setMessage(error.message);
    }
  };

  //   const addAllArtists = async () => {
  //     let artists = [];
  //     await db
  //       .collection("artists")
  //       .get()
  //       .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           artists.push(doc.data());
  //         });
  //       });

  //     let filtered = artists.filter(
  //       (x) =>
  //         x.uid !== "3B9fsewFSzfXyWvWm9d5QL9l04v1" &&
  //         x.uid !== "RShcWfc4AOXDDMpWES3PY9Xyh1H3" &&
  //         x.uid !== "g9KRtIX7BkhyNgIYiSeIGaXcalz2" &&
  //         x.uid !== "qrwneBL2xyRCDzQLF6EG0yMvsml2" &&
  //         x.uid !== "X4Hop3mfkcROWpNZYBdwlCOrMOj2"
  //     );

  //     filtered.forEach((doc) => {
  //       db.collection("promoters")
  //         .doc(process.env.REACT_APP_SITE_ADMIN_ID)
  //         .collection("comedians")
  //         .doc(doc.uid)
  //         .set(doc);
  //     });
  //   };

  return (
    <Box
      sx={{
        width: { xs: "100%" },
        paddingTop: { xs: "80px", md: "120px" },
        padding: 1,
        background: "black",
      }}
    >
      <Grid
        item
        container
        xs={12}
        style={{ padding: 10, justifyContent: "center" }}
      >
        <Grid
          item
          container
          xs={12}
          md={10}
          style={{ display: "flex", marginBottom: 10, alignItems: "center" }}
        >
          <Typography
            variant={deviceType === "mobile" ? "h5" : "h4"}
            sx={{ mr: 1, width: "100%", color: "white" }}
          >
            Register To Perform
          </Typography>
          <Typography
            variant={"body2"}
            sx={{ mr: 1, width: "100%", color: "white" }}
          >
            Fill out the form below.
          </Typography>

          <Grid item container xs={12} md={6} style={{ padding: 10 }}>
            <img
              src={mic}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            style={{
              overflowY: deviceType === "mobile" ? "scroll" : "hidden",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <Grid
              item
              container
              xs={12}
              md={8}
              style={{ justifyContent: "center" }}
            >
              <Typography
                variant="caption"
                style={{ width: "100%", color: "white" }}
              >
                Account Info
              </Typography>
              <TextField
                fullWidth
                required
                id="name"
                label="Full Legal Name"
                variant="outlined"
                value={fullName}
                error={!!errors.fullName}
                helperText={errors.fullName}
                style={{
                  marginBottom: 10,
                }}
                inputProps={{
                  style: {
                    background: defaultTheme.palette.white,
                    borderRadius: 4,
                  },
                }}
                onChange={(e) => handleChange(e, "name")}
              />
              <TextField
                fullWidth
                required
                id="stage-name"
                label="Stage Name"
                variant="outlined"
                value={artistName}
                error={!!errors.artistName}
                helperText={errors.artistName}
                style={{
                  marginBottom: 10,
                }}
                inputProps={{
                  style: {
                    background: defaultTheme.palette.white,
                    borderRadius: 4,
                  },
                }}
                onChange={(e) => handleChange(e, "stage name")}
              />
              <Typography
                variant="caption"
                style={{ width: "100%", color: "white" }}
              >
                What do you do?
              </Typography>
              <TextField
                select
                style={{ marginBottom: 10 }}
                fullWidth
                value={genre}
                onChange={(e) => handleChange(e, "genre")}
                variant="outlined"
                SelectProps={{
                  native: true,
                  style: {
                    background: defaultTheme.palette.white,
                    borderRadius: 4,
                  },
                }}
              >
                {["Poetry", "Comedy"].map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </TextField>

              <TextField
                required
                fullWidth
                id="phone"
                label="Phone"
                variant="outlined"
                value={phoneNumber}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                style={{ marginBottom: 10 }}
                onChange={(e) => handleChange(e, "phone")}
                inputProps={{
                  style: {
                    background: defaultTheme.palette.white,
                    borderRadius: 4,
                  },
                }}
              />
              <TextField
                fullWidth
                required
                id="email"
                label="Email"
                type="email"
                variant="outlined"
                value={email}
                error={!!errors.email}
                helperText={errors.email}
                style={{ marginBottom: 10 }}
                onChange={(e) => handleChange(e, "email")}
                inputProps={{
                  style: {
                    background: defaultTheme.palette.white,
                    borderRadius: 4,
                  },
                }}
              />

              <TextField
                fullWidth
                required
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                type="password"
                sx={{ marginBottom: "1rem" }}
                inputProps={{
                  style: {
                    background: defaultTheme.palette.white,
                    borderRadius: 4,
                  },
                }}
                error={password.length > 0 && password.length < 6}
                helperText={
                  password.length > 0 && password.length < 6
                    ? "Password must be at least 6 characters"
                    : ""
                }
              />
              {password && password.length >= 6 && (
                <TextField
                  required
                  fullWidth
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  variant="outlined"
                  type="password"
                  sx={{ marginBottom: "1rem" }}
                  inputProps={{
                    style: {
                      background: defaultTheme.palette.white,
                      borderRadius: 4,
                    },
                  }}
                  error={password !== confirmPassword}
                  helperText={
                    password !== confirmPassword ? "Passwords do not match" : ""
                  }
                />
              )}

              <Typography
                variant="caption"
                style={{ width: "100%", color: "white" }}
              >
                Social Media Links (optional)
              </Typography>

              <TextField
                fullWidth
                required
                label="Instagram"
                value={socialMediaLinks.instagram}
                onChange={(e) =>
                  setSocialMediaLinks({
                    ...socialMediaLinks,
                    instagram: e.target.value,
                  })
                }
                style={{ marginBottom: 10 }}
                inputProps={{
                  style: {
                    background: defaultTheme.palette.white,
                    borderRadius: 4,
                  },
                }}
              />

              <TextField
                fullWidth
                label="Twitter"
                value={socialMediaLinks.twitter}
                onChange={(e) =>
                  setSocialMediaLinks({
                    ...socialMediaLinks,
                    twitter: e.target.value,
                  })
                }
                style={{ marginBottom: 10 }}
                inputProps={{
                  style: {
                    background: defaultTheme.palette.white,
                    borderRadius: 4,
                  },
                }}
              />

              <TextField
                fullWidth
                label="Facebook"
                value={socialMediaLinks.facebook}
                onChange={(e) =>
                  setSocialMediaLinks({
                    ...socialMediaLinks,
                    facebook: e.target.value,
                  })
                }
                style={{ marginBottom: 10 }}
                inputProps={{
                  style: {
                    background: defaultTheme.palette.white,
                    borderRadius: 4,
                  },
                }}
              />

              <Button
                variant="contained"
                color="primary"
                disabled={
                  fullName === "" ||
                  artistName === "" ||
                  email === "" ||
                  phoneNumber === "" ||
                  socialMediaLinks.instagram === "" ||
                  (password !== "" && password !== confirmPassword) ||
                  password === ""
                }
                onClick={handleSignUp}
                style={{
                  color: defaultTheme.palette.white,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {loading ? (
                  <CircularProgress
                    style={{ width: 24, height: 24, color: "#00000050" }}
                  />
                ) : (
                  <>Register</>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegistrationScreen;
