import React, { useContext } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { AppContext } from "../App";

const Footer = () => {
  const { defaultTheme, navigate, scrollToTop } = useContext(AppContext);
  return (
    <Grid
      item
      container
      xs={12}
      //   sx={{ height: { xs: "100dvh", md: "70dvh" } }}
      style={{
        justifyContent: "center",
        alignItems: "center",
        minHeight: "30dvh",
        background: defaultTheme.palette.black,
        zIndex: 1,
      }}
    >
      <Grid item container xs={12} md={10}>
        <Grid item xs={6} md={3}>
          <Typography
            variant="h5"
            style={{ color: defaultTheme.palette.white }}
          >
            Resources
          </Typography>
          <List>
            <ListItem style={{ padding: 0 }}>
              <ListItemButton style={{ color: "white", fontSize: 12 }}>
                Schedule a Gig
              </ListItemButton>
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <ListItemButton
                style={{ color: "white", fontSize: 12 }}
                onClick={() => {
                  navigate("/register-to-perform");
                  scrollToTop();
                }}
              >
                Register To Perform
              </ListItemButton>
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <ListItemButton style={{ color: "white", fontSize: 12 }}>
                Club Memberships
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography
            variant="h5"
            style={{ color: defaultTheme.palette.white }}
          >
            Latest News
          </Typography>
          <List>
            {/* <ListItem style={{ padding: 0 }}>
              <ListItemButton style={{ color: "white", fontSize: 12 }}>
                Schedule
              </ListItemButton>
            </ListItem> */}
          </List>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography
            variant="h5"
            style={{ color: defaultTheme.palette.white }}
          >
            Help
          </Typography>
          <List>
            <ListItem style={{ padding: 0 }}>
              <ListItemButton style={{ color: "white", fontSize: 12 }}>
                Ticketing Support
              </ListItemButton>
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <ListItemButton
                style={{ color: "white", fontSize: 12 }}
                onClick={() => {
                  scrollToTop();
                  navigate("/terms-of-service");
                }}
              >
                Terms of Service
              </ListItemButton>
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <ListItemButton
                style={{ color: "white", fontSize: 12 }}
                onClick={() => {
                  navigate("/privacy-policy");
                  scrollToTop();
                }}
              >
                Privacy Policy
              </ListItemButton>
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <ListItemButton
                style={{ color: "white", fontSize: 12 }}
                onClick={() => {
                  navigate("/faqs");
                  scrollToTop();
                }}
              >
                FAQs
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
