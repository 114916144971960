// src/screens/ProfileScreen.js
import React, { useContext } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import Profile from "../components/Profile";
import { AppContext } from "../App";

const ProfileScreen = () => {
  const { userData, navigate } = useContext(AppContext);
  return (
    <Box sx={{ padding: "1rem", background: "white", width: "100%" }}>
      {userData.guest ? (
        <Grid
          item
          container
          xs={12}
          style={{ padding: 10, height: "100dvh", justifyContent: "center" }}
        >
          {/* user must sign in.. */}
          <Typography
            variant="h6"
            sx={{
              marginTop: "100px",
              marginBottom: 0,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            You must sign in to view your profile.
          </Typography>
          <Button
            onClick={() => navigate("/auth")}
            variant="contained"
            style={{ height: 40 }}
          >
            Sign In
          </Button>
        </Grid>
      ) : (
        <Profile />
      )}
    </Box>
  );
};

export default ProfileScreen;
