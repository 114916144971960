import React, { useContext, useEffect } from "react";
import { motion } from "framer-motion";
import logo from "../assets/LaughingZone.png";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../App";

const SplashScreen = ({}) => {
  const { defaultTheme, deviceType } = useContext(AppContext);
  return (
    <Grid
      item
      container
      xs={12}
      //   md={6}
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        background: "white",
      }}
    >
      <motion.div
        animate={{ opacity: 1, top: "30%" }}
        transition={{ duration: 1, delay: 0.25 }}
        style={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          opacity: 0,
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100dvh",
            containerType: "inline-size",
          }}
        >
          <img
            src={logo}
            alt=""
            style={{
              width: deviceType === "mobile" ? "50%" : "25%",
              height: "auto",
              objectFit: "contain",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -30%)",
              zIndex: 1,
            }}
          />

          <Typography
            variant="h2"
            sx={{
              //   fontWeight: "bold",
              color: "grey",
              position: "absolute",
              top: { xs: "64%", md: "61%" },
              left: "10%",
              transform: "translate(-10%, 0%)",
              fontSize: "15cqw",
              //   rotate: "-12deg",
              zIndex: 2,
            }}
          >
            {/* TIFFANY <strong>B</strong>
            <span style={{ color: defaultTheme.palette.secondary.main }}>
              .
            </span> */}
          </Typography>
          {/* <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              color: "white",
              position: "absolute",
              top: "70%",
              left: "10%",
              transform: "translate(-0%, 0%)",
              fontSize: "20cqw",
              //   rotate: "-12deg",
              zIndex: 2,
            }}
          >
            B.
          </Typography> */}
          {/* <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              color: "red",
              position: "absolute",
              bottom: "50%",
              left: "50%",
              transform: "translate(10%, 325%)",
              fontSize: "10cqw",
              rotate: "-12deg",
              zIndex: 2,
            }}
          >
            EXPERIENCE
          </Typography> */}
        </div>
      </motion.div>
      <motion.p
        animate={{ opacity: 1, bottom: "3%" }}
        transition={{ duration: 0.5, delay: 1 }}
        variant="caption"
        style={{
          position: "absolute",
          left: "50%",
          bottom: "-5%",
          transform: "translate(-50%, 0%)",
          //   color: "white",
          opacity: 0,
          fontSize: ".75rem",
          textAlign: "center",
        }}
      >
        Powered by TicketShare.
        <br />
        COMING SOON!
      </motion.p>
    </Grid>
  );
};

export default SplashScreen;
