// src/components/Hero.js
import React, { useContext } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import logo from "../assets/LaughingZone.png";
import { AppContext } from "../App";
import { Facebook, Instagram, X } from "@mui/icons-material";

const Hero = ({ homeRef }) => {
  const { defaultTheme, navigate, scrollToTop, artist, deviceType } =
    useContext(AppContext);

  const navigateToSocialMedia = (account) => {
    if (account.length > 0) {
      window.open(`${account}`, "_blank");
    }
  };

  return (
    <Grid
      ref={homeRef}
      item
      container
      xs={12}
      //   sx={{ height: { xs: "100dvh", md: "90dvh" } }}
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "100dvh",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          containerType: "inline-size",
        }}
      >
        <Box sx={{ width: { xs: "75dvw", md: "35dvw" } }}>
          <img
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
            src={logo}
            alt=""
          />
        </Box>
        {/* <Typography
          variant="h1"
          style={{
            fontWeight: "400",
            color: defaultTheme.palette.tirciary.main,
          }}
          sx={{
            fontSize: { xs: "15cqw", md: "8cqw" },
            marginTop: { xs: "-100px", md: "-0px" },
            width: "100%",
            textAlign: "center",
          }}
        >
          <strong>THE LAUGH ZONE</strong>
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          style={{
            // color: "red",
            letterSpacing: "2px",
            fontWeight: "bold",
          }}
          sx={{ fontSize: { xs: "4cqw", md: "1cqw" } }}
        >
          HOUSE OF COMEDY
        </Typography> */}
        <Grid
          item
          container
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          direction={deviceType === "mobile" ? "column" : "row"}
          sx={{ marginTop: { xs: "2%", md: "3%" } }}
        >
          <Button
            onClick={() => {
              navigate("/events");
              scrollToTop();
            }}
            variant="contained"
            color="primary"
            style={{
              color: defaultTheme.palette.white,
              borderRadius: 40,
              height: 40,
            }}
            sx={{
              fontSize: { xs: "3cqw", md: ".75cqw" },
            }}
          >
            Upcoming Events
          </Button>
          <Button
            onClick={() => {
              navigate("/register-to-perform");
              scrollToTop();
            }}
            variant="contained"
            color="tirciary"
            style={{
              color: defaultTheme.palette.black,
              borderRadius: 40,
              height: 40,
            }}
            sx={{
              fontSize: { xs: "3cqw", md: ".75cqw" },
              margin: { xs: "10px 0px", md: "0px 10px" },
            }}
          >
            Register to Perform
          </Button>
          <Button
            onClick={() => {
              navigate("/memberships");
              scrollToTop();
            }}
            variant="contained"
            color="secondary"
            style={{
              color: defaultTheme.palette.black,
              borderRadius: 40,
              height: 40,
            }}
            sx={{
              fontSize: { xs: "3cqw", md: ".75cqw" },
            }}
          >
            Memberships
          </Button>
        </Grid>
      </div>

      <Box
        sx={{
          position: "absolute",
          right: "2%",
          bottom: "2%",
          width: "fit-content",
          padding: "0px 5px",
          //   height: "40px",
          background: "white",
          borderRadius: "8px",
          zIndex: 4,
          textAlign: "right",
        }}
      >
        <Typography variant="caption">Social Media</Typography>
        <Grid item container xs={12}>
          <IconButton
            onClick={() => {
              navigateToSocialMedia(artist?.socialMediaLinks?.instagram);
            }}
            color="primary"
          >
            <Instagram />
          </IconButton>
          <IconButton
            onClick={() => {
              navigateToSocialMedia(artist?.socialMediaLinks?.instagram);
            }}
            color="secondary"
            style={{ marginLeft: 5 }}
          >
            <Facebook />
          </IconButton>
          <IconButton
            onClick={() => {
              navigateToSocialMedia(artist?.socialMediaLinks?.instagram);
            }}
            style={{ marginLeft: 5 }}
          >
            <X />
          </IconButton>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Hero;
