import { Typography } from "@mui/material";

export const theme = {
  palette: {
    primary: {
      main: "#c52022",
      dark: "#810809",
      light: "#ef5355",
    },
    secondary: {
      main: "#1dc5ec",
      dark: "#0c829d",
      light: "#5cd7f3",
    },
    tirciary: {
      main: "#e6c23b",
      dark: "#987d18",
      light: "#eed164",
    },
    error: {
      main: "#f44336",
      dark: "#d32f2f",
      light: "#e57373",
    },
    warning: {
      main: "#ff9800",
      dark: "#f57c00",
      light: "#ffe082",
    },
    info: {
      main: "#2196f3",
      dark: "#1976d2",
      light: "#90caf9",
    },
    success: {
      main: "#4caf50",
      dark: "#2e7d32",
      light: "#c8e6c9",
    },
    black: "#000000",
    white: "#ffffff",
    grey: {
      main: "#808080",
      light: "#d3d3d3",
      dark: "#606060",
    },
  },
  typography: {
    fontFamily: [`"Montserrat", sans-serif`],
  },
};
